/* eslint-disable no-param-reassign */
import {useReducer, useCallback} from 'react'
import axios from 'axios'
import * as actionTypes from './ActionTypes'
import {getErrorResponse} from '../utils/Utility'
import featuresAxios from '../utils/FeaturesAxios'

/**
 * initial state object used by useReducer
 */
const initialState = {
  loadingFeatures: false,
  errorLoadingFeatures: null,
  features: []  
}

/**
 * Function being used by useReducer
 * @param {object} prevState - current state object
 * @param {object} action - action with type and payload
 */
const httpReducer = (prevState, action) => {
  switch (action.type) {
    case actionTypes.GET_FEATURES_REQUEST_SENT:
      return {...initialState, loadingFeatures: true, errorLoadingFeatures: null, features: null}
    case actionTypes.GET_FEATURES_RECVD:
      return {
        ...prevState,
        loadingFeatures: false,
        features: action.features
      }      
    case actionTypes.CLEAR_GET_FEATURES:
      return {
        ...prevState,
        features: null,
        loadingFeatures: false,
        errorLoadingFeatures: null
      }
    case actionTypes.CLEAR_GET_FEATURES_ERROR:
       return {
        ...prevState,
        errorLoadingFeatures: null,
      }
    case actionTypes.GET_FEATURES_ERROR:
       return {
        ...prevState,
        loadingFeatures: false,
        errorLoadingFeatures: action.errorLoadingFeatures,
      }              

    default:
      return new Error(`Action type ${action.type} not handled.`)
  }
}

/**
 * Custom hook for handling user CRUD operations
 */
const useFeaturesHook = () => {
  const [httpState, dispatch] = useReducer(httpReducer, {...initialState})

  /**
   * Gets user from usermanager api
   */
  const clearFeatures = useCallback(() => {
    dispatch({type: actionTypes.CLEAR_GET_FEATURES})
  }, [])

  const clearErrorLoadingFeatures = useCallback(() => {
    dispatch({type: actionTypes.CLEAR_GET_FEATURES_ERROR})
  }, [])


  
  const getFeatures = useCallback(() => {
    const {CancelToken} = axios
    const source = CancelToken.source()

    dispatch({type: actionTypes.GET_FEATURES_REQUEST_SENT})
           
      featuresAxios
      .getFeatures()
      .then(response => {

        // dispatch
        dispatch({
          type: actionTypes.GET_FEATURES_RECVD,
          features: response.data
        })
      })
      .catch(err => {
        dispatch({type: actionTypes.GET_FEATURES_ERROR, errorLoadingFeatures: getErrorResponse(err)})
      })

    return () => {
      source.cancel()
    }
  }, [])

  /**
   * Returns props, functions and methods to use for derived page/component
   * Sample Usage: const { isGetTmDataLoading, user, getTmData, clearTmData } = useTmDataHook()
   */
  return {
    isloadingFeatures: httpState.loadingFeatures,        
    features: httpState.features,
    errorLoadingFeatures: httpState.errorLoadingFeatures,
    getFeatures,
    clearFeatures,
    clearErrorLoadingFeatures    
  }
}

export default useFeaturesHook

